/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'material-icons/iconfont/material-icons.scss';
@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined';

.full-width-input {
    width: 100%;
  }

  
@media (width > 600px) {

  blockquote {
      font-size: 18px;
      width: 500px;
      margin: 0.25em 0;
      padding: 0.35em 40px;
  }
  
  blockquote:before {
      padding-left: 10px;
      font-size: 80px;
  }
  
  blockquote cite {
      font-size: 14px;
      margin-top: 5px;
  }
  
  }
  
  @media (width <= 600px) {
  
      blockquote {
          font-size: 14px;
          width: 98%;
          margin: 0.25em 0;
          padding: 0.35em 20px;
      }
      
      blockquote:before {
          padding-left: 4px;
          font-size: 10px;
      }
      
      blockquote cite {
          font-size: 14px;
          margin-top: 4px;
      }
      
      }
  
      
  blockquote {
      font-family: Georgia, serif;
      font-style: italic;
      line-height: 1.45;
      position: relative;
      color: #383838;
  }
  
  blockquote:before {
      display: block;
      content: "\201C";
      position: absolute;
      left: -20px;
      top: -20px;
      color: #7a7a7a;
  }
  
  blockquote cite {
      color: #999999;
      display: block;
  }
  
  blockquote cite:before {
      content: "\2014 \2009";
  }
  
  body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      background-color: #f4f4f4;
      margin: 0;
      padding: 20px;
  }
  h1, h2 {
      color: #333;
  }
  pre > code {
	display: block;
	background: #f1f1f1;
	padding: 1.33em;
	border-radius: 0.33em;
    overflow-x: auto;
}

  @media (max-width: 800) {

    pre {    
        padding: 1px;
        font-size: 0.8rem;

    }
 }

  a {
      color: #0275d8;
      text-decoration: none;
      font-size: medium; 
      vertical-align: middle;
      font-size: 14px;
  }
  a:hover {
      text-decoration: underline;
  }
  .container {
      max-width: 900px;
      margin: auto;
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  footer {
      margin-top: 20px;
      text-align: center;
      font-size: 0.9em;
      color: #666;
  }
  ul {
      list-style-type: none;
      padding-left: 20px;
  }    
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  
  .bullets {
    list-style-type: disc
  }